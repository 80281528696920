import { PRICE_MAP } from '../constants/prices';

export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'gbp':
      return '£';
    case 'usd':
      return '$';
    case 'eur':
      return '€';
    default:
      return '£';
  }
};

export const getCurrencyLocation = (location) => {
  if (location === 'US' || location === 'CA') {
    return 'usd';
  }
  if (location === 'GB' || !location) {
    return 'gbp';
  }
  return 'eur';
};

export const getPriceByCurrency = (currencyCode, price) => {
  if (!price || !PRICE_MAP[price] || !currencyCode) return NaN;
  return PRICE_MAP[price][currencyCode];
};

export const getFormattedPrice = (currency, total) => {
  const symbol = getCurrencySymbol(currency);
  const formattedTotal = total.toFixed(2);
  return currency === 'eur'
    ? `${formattedTotal}${symbol}`
    : `${symbol}${formattedTotal}`;
};
