import { PropTypes } from 'prop-types';
import React from 'react';

import CartProvider from '../Providers/CartProvider';
import OneTrustProvider from '../Providers/OnetrustProvider';
import ViewportProvider from '../Providers/ViewportProvider';

const Layout = ({ children }) => {
  return (
    <OneTrustProvider>
      <CartProvider>
        <ViewportProvider>{children}</ViewportProvider>
      </CartProvider>
    </OneTrustProvider>
  );
};
Layout.propTypes = {
  children: PropTypes.object,
};
export default Layout;
