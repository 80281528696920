// When editing or adding an entry in this file, you MUST make a corresponding
// change in Big Commerce. In the Big Commerce Courier store, visit:
// Apps > Bold Multi-Currency > Currencies

// For all currencies except pounds, click the gear next to the currency,
// and scroll down to Exchange Rate Rounding Rules.
// There will be entries here to serve as a model, but basically, we are
// "rounding" the price in gbp to the correct price in the target currency.

// E.g. "Prices that end between  €6.00 and  €6.01 should end with  €7.50"
// Translated: If this thing costs 6 gbp, display 7.5 euros.

// Yes, this is an off-label use of this interface and extremely goofy.

export const PRICE_MAP = {
  10: {
    eur: 12,
    gbp: 10,
    usd: 15,
  },
  12: {
    eur: 14.5,
    gbp: 12,
    usd: 17.5,
  },
  15: {
    eur: 18,
    gbp: 15,
    usd: 19.99,
  },
  16.5: {
    eur: 20.5,
    gbp: 16.5,
    usd: 22.5,
  },
  35: {
    eur: 39.9,
    gbp: 35,
    usd: 60,
  },
  36: {
    eur: 45,
    gbp: 36,
    usd: 48,
  },
  40: {
    eur: 45,
    gbp: 40,
    usd: 65,
  },
  45: {
    eur: 55,
    gbp: 45,
    usd: 60,
  },
  57.5: {
    eur: 65,
    gbp: 57.5,
    usd: 95,
  },
  6: {
    eur: 7.5,
    gbp: 6,
    usd: 8,
  },
  60: {
    eur: 70,
    gbp: 60,
    usd: 80,
  },
};
