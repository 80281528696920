export const BREAKPOINTS = {
  desktop: 1100,
  desktopLG: 1600,
  desktopMD: 1440,
  desktopXL: 2560,
  desktopXS: 1100,
  mobileLG: 375,
  mobileXL: 600,
  tablet: 600,
  tabletLG: 768,
  tabletMD: 601,
  tabletXL: 1099,
};
