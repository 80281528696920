import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';

import { CartActionTypes } from '../../actions';
import { CartContext, OneTrustContext } from '../../contexts';
import { cartInitialState, cartReducer } from '../../reducers/cart-reducer';
import { getCurrencyLocation } from '../../utils/currency';

const CartProvider = ({ children }) => {
  const [cartState, cartDispatch] = useReducer(
    cartReducer,
    cartInitialState,
    () => {
      const localData =
        typeof window !== 'undefined' && localStorage.getItem('cartState');
      return localData
        ? // to prevent caching issues when adding new updates to cart,
          // where the new item is undefined in local storage
          { ...cartInitialState, ...JSON.parse(localData) }
        : cartInitialState;
    },
  );

  // updates localStorage on cart change
  useEffect(() => {
    localStorage.setItem('cartState', JSON.stringify(cartState));
  }, [cartState]);

  // checks if localStorage cart is still valid on mount
  useEffect(() => {
    const cartId = localStorage.getItem('cartId');
    if (cartId) {
      (async () => {
        try {
          await axios({
            method: 'get',
            params: { cartId: JSON.parse(cartId) },
            url: '/api/cart-bold',
          });
        } catch (error) {
          cartDispatch({
            type: CartActionTypes.RESET,
          });
          localStorage.removeItem('cartId');
        }
      })();
    }
  }, []);

  // update currency based on location if no preferred currency is set
  // tech debt: look into more performant ways to update state between providers, instead of useEffect
  const { location } = useContext(OneTrustContext);
  useEffect(() => {
    const { preferredCurrency } = cartState;
    const currency = getCurrencyLocation(location);

    if (!location) {
      return;
    }

    if (!preferredCurrency) {
      cartDispatch({
        payload: {
          currency,
          preferredCurrency: true,
        },
        type: CartActionTypes.SET_CURRENCY,
      });
    }
  }, [location]);

  return (
    <CartContext.Provider value={{ cartDispatch, cartState }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
