/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// https://www.gatsbyjs.com/plugins/gatsby-plugin-sass/
import './src/styles/index.scss';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

if (process.env.GATSBY_APP_DEPLOY_ID !== 0) {
  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    appVersion: process.env.GATSBY_APP_DEPLOY_ID,
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.GATSBY_APP_CONTEXT || process.env.NODE_ENV,
  });
}

export const wrapRootElement = ({ element }) => {
  if (Bugsnag.isStarted()) {
    const ErrorBoundary = Bugsnag.getPlugin('react');
    // eslint-disable-next-line react/jsx-filename-extension
    return <ErrorBoundary>{element}</ErrorBoundary>;
  }
  return element;
};
