import React, { useEffect, useState } from 'react';

import { BREAKPOINTS } from '../../constants/breakpoints';
import { ViewportContext } from '../../contexts';

const ViewportProvider = ({ children }) => {
  const [viewport, setViewport] = useState('mobile');

  const handleResize = () => {
    if (window.innerWidth >= BREAKPOINTS.desktop) {
      setViewport('desktop');
      return;
    }
    if (window.innerWidth >= BREAKPOINTS.tablet) {
      setViewport('tablet');
      return;
    }
    setViewport('mobile');
  };

  useEffect(() => {
    window.addEventListener('resize', () => handleResize(window));
    handleResize();

    return () => {
      window.removeEventListener('resize', () => handleResize(window));
    };
  }, []);
  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
