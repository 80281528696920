import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { OneTrustContext } from '../../contexts';

const OneTrustProvider = ({ children }) => {
  const [onetrust, setOnetrust] = useState({ init: false, location: null });

  const updateLocation = (content) => {
    if (content) {
      setOnetrust({ ...onetrust, init: true, location: content });
    }
  };

  useEffect(() => {
    const target = document.querySelector("meta[name='onetrust-geolocation']");
    const config = { attributes: true };
    updateLocation(target.content);
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        const content = get(mutation, 'target.content');
        updateLocation(content);
      });
    };
    const observer = new MutationObserver(callback);

    observer.observe(target, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <OneTrustContext.Provider value={onetrust}>
      {children}
    </OneTrustContext.Provider>
  );
};

export default OneTrustProvider;
